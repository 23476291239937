

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import AboutUs from '../components/Setting/AboutUs';
import RefundPolicy from '../components/Setting/RefundPolicy';
import PrivacyPolicy from '../components/Setting/PrivacyPolicy';
import TermsConditions from '../components/Setting/TermsConditions';
function AdvertisementModule() {
  return (
    <div className="App">
    
<Routes>


< Route path='/AboutUs' element={<Layout children={AboutUs()}/>}/>
< Route path='/PrivacyPolicy' element={<Layout children={PrivacyPolicy()}/>}/>
< Route path='/TermsConditions' element={<Layout children={TermsConditions()}/>}/>
< Route path='/RefundPolicy' element={<Layout children={RefundPolicy()}/>}/>


      </Routes>
    </div>
  );
}

export default AdvertisementModule;