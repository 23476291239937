
import { TRANSACTIONS ,TRANSACTIONDATELIS ,ALLTRANSACTIONS } from './../constants/actionTypes';
import * as api from './../api/index.js';



  export const all_transaction_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_transaction_list(formData);
      
      dispatch({ type: TRANSACTIONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const transaction_details = (formData) => async (dispatch) => {
    try{
      const { data } = await api.transaction_details(formData);

      dispatch({ type: TRANSACTIONDATELIS , payload:data})
    } catch (error) {
      console.log(error)
    }
  }
  export const all_successfull_transaction = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_successfull_transaction(formData);
      
      dispatch({ type: ALLTRANSACTIONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  