import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Genre from '../components/Masters/Genre/Genre';

function GenreModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateGenre' element={<Layout children={Genre()} />} />
                < Route path='/EditGenre' element={<Layout children={Genre()} />} />
                < Route path='/Genre' element={<Layout children={Genre()} />} />
            </Routes>
        </div>
    )
}
export default GenreModule