

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './../components/Dashboard/Dashboard';
import Layout from '../components/Layout/Layout';

function DashboardModule() {
  return (
    <div className="App">
    
<Routes>
   {/* Test */}
   < Route path='/' element={<Layout children={Dashboard()}/>}/>
  {/* test */}
< Route path='/Dashboard' element={<Layout children={Dashboard()}/>}/>



      </Routes>
    </div>
  );
}

export default DashboardModule;
