import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import country from '../components/Masters/Country/Country';

function CountryModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateCountry' element={<Layout children={country()} />} />
                < Route path='/EditCountry' element={<Layout children={country()} />} />
                < Route path='/Country' element={<Layout children={country()} />} />
            </Routes>
        </div>
    )
}
export default CountryModule