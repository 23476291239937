import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Cast from '../components/Masters/Cast/Cast';

function CastModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateCast' element={<Layout children={Cast()} />} />
                < Route path='/EditCast' element={<Layout children={Cast()} />} />
                < Route path='/Cast' element={<Layout children={Cast()} />} />
            </Routes>
        </div>
    )
}
export default CastModule