import { Routes, Route  } from "react-router-dom";
import Layout from "../components/Layout/Layout";

import AllTransaction from "../components/TransactionList/AllTransaction";

function AllTransactionModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/AllTransaction"
          element={<Layout children={AllTransaction()} />}
        />
      </Routes>
    </div>
  );
}

export default AllTransactionModule;
