import React from 'react';
import { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
export default function MoviesDownload() {
  const location = useLocation()
  const [tableData, setTableData] = useState({
    tableTitle: "Videos Downloaded",
    disableDelete: true,
    tableHead: [

      {
        id: 'movie',
        label: 'Video Name',
        isSpecial:true,
        align:"left"
      },
      {
        id: 'created_at',
        label: 'Download On',
      },
      // {
      //   id: 'created_at',
      //   label: 'download By',
      // },
      // {
      //   id: 'user',
      //   label: 'Watched By',
      // },
      {
        id: 'device_id',
        label: 'Device Name',
      },
      // {
      //   id: 'location',
      //   label: 'Location',
      // },

    ],
    tableBody: [

    ]
  })
  const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  useMemo(() => {
    const temp = tableData
    temp.tableBody = location.state?.data || []
    setTableData({ ...temp })
  }, [location])
  console.log(location,"1111")
  return (
    <>




      <ListTable tableData={tableData} key={"ListTable"} setForm={setForm} setTableData={setTableData} setIsEdit={setIsEdit} />


    </>
  );
}
