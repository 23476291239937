import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import poster from "./../../images/poster.jpg";
import "./ProductDetailsContent.css";
import Features from "./Features";
import ProductProfile from "./ProductProfile";
import {movie_details} from "./../../actions/Movie/movie"
import {episode_details} from "./../../actions/WebSeries/episode"
import { useEffect,useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { IMAGE } from "./../../api/index";
import WatchedCustomers from './WatchedCustomers'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const ProductDetailsContent = () => {
	const dispatch=useDispatch()
	const location = useLocation()
	const role=useSelector((state) => state.layout.role)
	const path = location.pathname.split('/').filter((x) => x)[1];
// console.log(path1,"879665")
	const formData= new FormData()
	formData.append("id",location.state?.id)
	useMemo(()=>{
		if(path=="MovieDetails")
	dispatch(movie_details(formData))
// 	else if(path=="WebEpisode")
// 	formData.append("series_type" ,"Web Series")
// else formData.append("series_type" ,"Super Short Series")
	dispatch(episode_details(formData))},[location.state?.id])
	const data =useSelector((state) =>path=="MovieDetails"? state.movies.movie?.Data:state?.webseries?.episode?.Data)
	const download =useSelector((state) =>path=="MovieDetails"? state.movies.movie?.Data:state?.webseries?.episode?.download_count)

	const cast = useSelector((state) =>state.movies.movie?.Cast);
	const watch_hours = useSelector((state) =>path=="MovieDetails"? state.movies.movie?.watch_hours:state.webseries);
	const Subtitle_list = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.subtitle_list :state.webseries?.episode?.subtitle);
	const Audio_list = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.audio_file_list : state.webseries?.episode?.audio)
	const SubtitleCount = useSelector((state) => path == "MovieDetails" ? state.movies.movie?.subtitle_count : state.webseries?.episode?.subtitle_count)
	const AudioCount = useSelector((state) => path == "MovieDetails" ? state?.movies.movie?.audio_file_count :state.webseries?.episode?.audio_file_count)
	const country_price_list = useSelector((state) =>state?.movies.movie?.price_by_country)

	const earning =useSelector((state) =>state.movies.movie)
	// const earning1 =useSelector((state) =>state)

	
	const access=path=="MovieDetails"?data?.movie_access:data?.series_name?.series_type
	// console.log(location,"110000")
	return (
		<>
			<Grid
				container
				rowSpacing={1}
				justifyContent="center"
				columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
			>

				<Grid item spacing={4} xs={12} sm={12} md={6} lg={7} xl={7}>
					
					<Card
						sx={{
							boxShadow: "none",
							borderRadius: "10px",
							p: "15px 25px",
							mb: "15px",
						}}
					>
						<Box mt={2}>
							<Tabs className="product-details-tabs">
								<TabList>
									<Tab>{path=="MovieDetails"?"Video":"Episode"}</Tab>
									<Tab>Details </Tab>
								{path=="MovieDetails"&&	<Tab>Cast </Tab>}
							

									
								</TabList>

								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												mb: "15px",
												fontWeight: 500,
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>{path=="MovieDetails" ?"Video":"Episode"} Detail </span>
										</Typography>

										<Box>
										
										{path=="MovieDetails"&&	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Sub Category:
												</span>
												{data?.movie_subcategory}
											</Typography>}
											{	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Access:
												</span>
												{ path=="MovieDetails" ? data?.movie_access : data?.series_name?.series_type}
											</Typography>}
											{path!="MovieDetails"&&	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Series Name:
												</span>
												{data?.series_name?.series_name}
											</Typography>}
											{path!="MovieDetails"&&	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													season :
												</span>
												{data?.season_name}
											</Typography>}
											{/* <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Access:
												</span>
												{path=="MovieDetails"?data?.movie_access:data?.series_name?.series_type}
											</Typography> */}

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Language:
												</span>
												{path=="MovieDetails"?data?.movie_language:data?.series_name?.language?.language_name}
											</Typography>
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Duration:
												</span>
												{path=="MovieDetails"?data?.movie_duration:data?.episode_duration}
											</Typography>
											{/* {path=="MovieDetails"&& <Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Available For:
												</span>
												{data?.available_for_days} days
											</Typography>} */}

											
										</Box>
									</Card>
								</TabPanel>
								<TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "25px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "15px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Video Details</span>
										</Typography>

										<Box>
										

											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Allow Download :
												</span>
												{data?.allow_download}
											</Typography>
											{data?.movie_ownership == "Producer" && 	<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													 Commission :
												</span>
												{data?.distributor?.commission} %
											</Typography>}
											 {(access=="TVOD")&&<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Price Per View (Rent):
												</span>
												{data?.rental_price||data?.series_name?.rental_price} INR
											</Typography>}
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Is This Video 18+:
												</span>
												{data?.movie_is_for18Plus||data?.episode_is_for18Plus}
											</Typography>
										
											{access=="TVOD"&&<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Available For:
												</span>
												{data?.available_for_days} days
											</Typography>}
											<Typography
												sx={{
													fontSize: 13,
													mt: "10px",
													display: "flex",
												}}
											>
												<span
													style={{
														width: "150px",
														fontWeight: "500",
													}}
													className="mr-10px"
												>
													Release Date:
												</span>
												{data?.release_date}
											</Typography>
											
										</Box>
									</Card>
								</TabPanel>
								{path=="MovieDetails" && <TabPanel>
									<Card
										sx={{
											boxShadow: "none",
											borderRadius: "10px",
											p: "22px 20px",
											mb: "10px",
											backgroundColor: "#F7FAFF",
											
										}}
									>
										<Typography
											as="h3"
											sx={{
												fontSize: 16,
												fontWeight: 500,
												mb: "10px",
												mt: "-25px",
												ml: "-25px",
												mr: "-25px",
												padding: "10px 25px",
												borderRadius: "8px 8px 0px 0px",
												background:
													"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

												color: "black",
											}}
										>
											<span style={{color:"#fff"}}>Cast</span>
										</Typography>

										<Box>
										<Card
												sx={{
													fontSize: 13,
													mt: "10px",
													background:"none",
													display:"flex",
													boxShadow:'none',
													color:"black",
													
												}}
											>
											{cast?.map((ele)=>(
											
											<Card
												sx={{
													fontSize: 13,
													// mt: "10px",
													background:"none",
													display:"flex",
													boxShadow:'none',
													color:"black",
													flexDirection:"column",
													textAlign:"center",
													width:"fit-content",
													ml:"15px"
												}}
											>
												
											<p>{ele?.cast_name}</p>	
												{/* <p>{ele?.cast_type}</p> */}
												<img src={IMAGE+ele?.cast_image} width="100px" style={{borderRadius:"10px"}}/>
											
												</Card>
											
											
))}</Card>
											
										</Box>
									</Card>
								</TabPanel>}
							
							
								
								
							</Tabs>
						</Box>
					</Card>
					
					{/* <Grid
						container
						item
						xs={12}
						md={12}
						lg={12}
						xl={12}
						rowSpacing={1}
						justifyContent="space-evenly"
						columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
					>
						<Card
							sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px",
								mb: "15px",
								width: "100%",
								display: "flex",
								justifyContent: "center",
								background: "transparent",
								textAlign: "center",
								color: "black",
							}}
						>
							<div>
								<img
									src={IMAGE+(data?.movie_poster||data?.episode_poster)}
									alt="Image"
									style={{
										display: "block",
										borderRadius: "10px",
										margin: "0 auto",
										height: "250px",
									}}
								/>
								<p>Poster</p>
							</div>
						</Card>
					
					</Grid> */}
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={5} xl={5} key={"sdcsdc"}>
					<ProductProfile data={data} path={path} download={download}/>
					<Features earning={earning} path={path}/>
					
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			{<WatchedCustomers id={location.state?.id} path={path}/>}</Grid>

				{/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Subtitle List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Language</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{SubtitleCount !== 0 ?
										<TableBody>
											{Subtitle_list?.map((sub) => (

												<TableRow>
													<TableCell>
														{sub?.subtitle_language}
													</TableCell>
													<TableCell>
														<a href={sub?.subtitle_file} target="_blank" download="new-file">{sub?.subtitle_file.split("/")[5]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No subtitle is available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
					<Card sx={{
						boxShadow: "none",
						borderRadius: "10px",
						p: "15px 25px",
						mb: "15px",
					}} >
						<Box>

							<Card sx={{
								boxShadow: "none",
								borderRadius: "10px",
								p: "25px 20px",
								mb: "10px",
								backgroundColor: "#F7FAFF",

							}}>
								<Typography
									as="h3"
									sx={{
										fontSize: 16,
										mb: "15px",
										fontWeight: 500,
										mt: "-25px",
										ml: "-25px",
										mr: "-25px",
										padding: "10px 25px",
										borderRadius: "8px 8px 0px 0px",
										background:
											"linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

										color: "black",
									}}
								>
									<span style={{color:"#fff"}}>Audio File List</span>
								</Typography>
								<Table>
									<TableHead>
										<TableRow style={{ fontWeight: 900, fontSize: "25px" }}>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>Audio</TableCell>
											<TableCell style={{ fontWeight: 900, fontSize: "17px" }}>File</TableCell>
										</TableRow>
									</TableHead>
									{AudioCount !== 0 ?
										<TableBody>
											{Audio_list?.map((sub) => (
												<TableRow>
													<TableCell>
														{sub?.audio_language}
													</TableCell>
													<TableCell>
														<a href={sub?.audio_file} target="_blank" download="new-file">{sub?.audio_file.split("/")[5]}</a>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
										: <TableBody align="center">

											No Audio files are available for this Movie
										</TableBody>}

								</Table>

							</Card>


						</Box>

					</Card>
				</Grid> */}
			</Grid>
		</>
	);
};

export default ProductDetailsContent;
