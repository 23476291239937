import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Season from "../components/WebSeries/Season/Season";
function SeasonModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Season" element={<Layout children={Season()} />} />
        <Route path="/CreateSeason" element={<Layout children={Season()} />} />
        <Route path="/EditSeason" element={<Layout children={Season()} />} />
      </Routes>
    </div>
  );
}

export default SeasonModule;
