import React from 'react';
import { useState, useMemo } from 'react';
import { Link } from "react-router-dom";
import styles from './../../styles/PageTitle.module.css'
import ListTable from '../utils/Table'
import { useLocation } from "react-router-dom";
export default function MoviesWatched() {
  const location = useLocation()
  const [tableData, setTableData] = useState({
    tableTitle: "Video Watched",
    disableDelete: true,
    tableHead: [

      {
        id: 'name1',
        label: "Video Name",
        isSpecial:true,
        align:"left"
        // link:"/Customer/CustomerDetail/MoviesWatched",
      },
      {
        id: 'watch_hours',
        label: 'Watchhours',
      },
      {
        id: 'last_watch_on',
        label: 'Last Seen',
      },
      // {
      //   id: 'watch_by',
      //   label: 'Watched By',
      // },
      {
        id: 'device_name',
        label: 'Device',
      },
      {
        id: 'location',
        label: 'Location',
      },
    ],
    tableBody: [

    ]
  })
  // const [form, setForm] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  useMemo(() => {
    const temp = tableData
    temp.tableBody= location.state?.data?.map((state) => ({
      ...state,
      name1: state?.name?.length <= 40 ? state?.name : state?.name?.substring(0, 40) + "..."
    })) 
    setTableData({ ...temp })
  }, [location.state?.data])
  return (
    <>



      <ListTable tableData={tableData} key={"ListTable"} setTableData={setTableData} setIsEdit={setIsEdit} />


    </>
  );
}
