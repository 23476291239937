
import { MESSAGE,NOTIFICATIONS,NOTIFICATIONS_COUNT } from '../constants/actionTypes';
import * as api from '../api/index.js';


export const notification_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.notification_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const notification_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.notification_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const notification_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.notification_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_notification_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_notification_list(formData);
      
      dispatch({ type: NOTIFICATIONS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const notification_unread_count = (formData) => async (dispatch) => {
    try {
      const { data } = await api.notification_unread_count(formData);
      
      dispatch({ type: NOTIFICATIONS_COUNT, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  
  export const notification_read_status_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.notification_read_status_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };