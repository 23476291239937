
import { useSelector } from "react-redux"

export const TableData = () => {
  const rights = useSelector((state) => state.layout.rights)
  const role = useSelector((state) => state.layout.role)

  return {
    tableTitle: "Series",
    deleteAccess: rights?.["TV Shows"]?.["delete"] == "true",
    tableHead: [
      {
        id: 'series_name',
        label: 'Series Name',
        link:"/Series/Series/SeriesDetails",
        // color:"#4267B2"
      },
      {
        id: 'series_poster',
        label: 'Image',
        isImage: true
      },
      // {
      //   id: 'season',
      //   label: 'Season',
      // },
      {
        id: 'series_type',
        label: 'Access Type',
      },
      // {
      //   id: 'seriesViews',
      //   label: 'Views',
      // },


      {
        id: 'language',
        label: 'Language',
      },
      {
        id: 'status',
        label: 'Status',
        isButtonDisplay: true

      },
      role !== "Producer" &&    {
        id: 'edit',
        label: 'Update',
        access: rights?.["TV Shows"]?.["edit"] == "true"
      },
    ],
    tableBody: [
    
    ],
    filterColumn: [
      {
        id: "1",
        title: "Access Type",
        name: "series_type",
        options: ["FREE", "TVOD", "SVOD"],
      },
      {
        id: "2",
        title: "Language",
        name: "language",
        options: ["English", "Hindi", "Gujarati"],
      },

      // {
      //   id: "4",
      //   title: "Ownership",
      //   name: "series_ownership",
      //   options: ["In house", "Collaborator"],
      // },
     
    ]
  }
}