import * as actionType from "../constants/actionTypes";

const transactionsReducer = (state = { transactionsData: null }, action) => {
	switch (action.type) {
		
		case actionType.TRANSACTIONS:
			return {...state,transactions: action.payload};
	
			case actionType.TRANSACTIONDATELIS:
      return { ...state, transactionsDetails: action.payload };

	  case actionType.ALLTRANSACTIONS :
		return {...state,alltransactionslist : action.payload}

		default:
			return state;
	}
};

export default transactionsReducer;
