import React, { useState, useMemo } from "react";
import Card from "@mui/material/Card";
import { Typography, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Form from "./Form";
import copy from "copy-to-clipboard";
import Button from "@mui/material/Button";

export default function MultiStepForm({
  formStructure,
  formTitle,
  setForm,
  form,
  isEdit,
  isProducerForm,
  handleSubmit,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [allowNext, setAllowNext] = useState(false);
  const [text, setText] = useState("Add text you want to copy");
  // console.log(formTitle[0], "15445");
  const newFormStru = [...formTitle];
  const newForm12 = newFormStru.shift();
  const finalformTitle = newFormStru.unshift("");
  // console.log(newFormStru, "147258");
  useMemo(() => {
    var requiredFieldCount = 0;
    var requiredFieldFilledCount = 0;
    formStructure[activeStep].map((value) => {
      // console.log(form, "test 06-08-2023")
      if (value.required && value.display != "none") {
        requiredFieldCount = requiredFieldCount + 1;
        if (
          Object.keys(form).includes(value.name) &&
          form[value.name] != "" &&
          form[value.name] != [] &&
          form[value.name] != null
        ) {
          requiredFieldFilledCount = requiredFieldFilledCount + 1;
        }
      }
    });
    if (requiredFieldFilledCount == requiredFieldCount) setAllowNext(true);
    else setAllowNext(false);
  }, [form, activeStep]);
  const copyToClipboard = () => {
    copy("http://122.170.109.73:9000/ProducerForm");
    alert(`You have copied URL`);
};
  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Card
          sx={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "10px",
            p: "25px",
            mb: "15px",
            backgroundColor: "#f8fcff",
          }}
        >
          {isProducerForm && (
            <Box sx={{ width: "100%" }}>
              <Grid item xs={12} md={12} lg={12}>
                <p
                  style={{
                    margin: "1rem",
                    fontWeight: "700",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  <h2>Digital Form for Content Submission:</h2>
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    margin: "1rem",
                    color: "black",
                  }}
                >
                  {/* At 24SEVENFLIX4U, we invite independent filmmakers and
                  talents from all corners of the world to join us in this
                  mission. Our platform provides a unique opportunity to
                  showcase your work, recoup production costs, and share your
                  creative vision with a global audience. */}
                  Join us at 24 SEVEN FLIX4U, where we do not discriminate based
                  on region or language. We wholeheartedly invite New &
                  Independent Filmmakers from around the globe to submit their
                  content. Our platform values stories over names, prioritizing
                  passion over money. Say goodbye to rejections and
                  exploitations – submit, showcase, and earn while reaching a
                  global audience. Your stories deserve a platform that respects
                  your craft. Submit now via our Digital Submission Form and be
                  a part of this mission!
                </p>
                <p className="ProducerFormNote">
                  Note : To upload your content from a laptop or desktop
                  computer, Click Below URL To copy the Link and Paste into a
                  web browser such as Google Chrome or Safari, fill out the form
                  with proper information, and submit it.
                </p>
                <p className="ProducerFormNote" style={{color:"black"}}  onClick={copyToClipboard}>http://122.170.109.73:9000/ProducerForm  <ContentCopyIcon fontSize="small"/></p>
               
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep + 1}
              alternativeLabel
              className="direction-ltr"
            >
              {formTitle.map((label, index) => (
                <Step key={index + "step"}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Form
            formStructure={formStructure[activeStep]}
            handleSubmit={handleSubmit}
            formTitle={formTitle[activeStep]}
            key={"Form"}
            setForm={setForm}
            form={form}
            isEdit={isEdit}
            isMulti={true}
            errorMessage={errorMessage}
            setAllowNext={setAllowNext}
          />
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep > 0 && (
              <Button
                variant="contained"
                style={{
                  background:
                    "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
                  color: "#fff",
                }}
                onClick={() => {
                  setActiveStep((prev) => prev - 1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep < formTitle.length - 1 ? (
              <Button
                variant="contained"
                style={{
                  background: allowNext
                    ? "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)"
                    : "linear-gradient(225deg,  var(--gradientColorLightest1) 0%, var(--gradientColorLightest2) 91.25%)",
                  color: "#fff",
                }}
                onClick={() => {
                  if (allowNext) {
                    setActiveStep((prev) => prev + 1);
                    setErrorMessage(false);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  } else {
                    setErrorMessage(true);
                  }
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  background:
                    "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
                  color: "#fff",
                }}
                //  type="submit"
                onClick={(e) =>
                  allowNext
                    ? (handleSubmit(e), setErrorMessage(false))
                    : setErrorMessage(true)
                }
              >
                {isEdit ? "UPDATE" : "SUBMIT"}
              </Button>
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
}
