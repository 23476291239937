import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Language from '../components/Masters/Language/Language';

function LanguageModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateLanguage/' element={<Layout children={Language()} />} />
                < Route path='/EditLanguage/' element={<Layout children={Language()} />} />
                < Route path='/Language/' element={<Layout children={Language()} />} />
            </Routes>
        </div>
    )
}
export default LanguageModule