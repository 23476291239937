// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurrentDate_currentDate__hlgkC {\n  border: 1px solid #E2E8F0;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  color: var(--primaryColor);\n  padding: 10px 20px 10px 50px;\n  font-weight: 500; \n  font-size: 14px;\n  position: relative;\n}\n.CurrentDate_currentDate__hlgkC i {\n  margin-right: 10px;\n  font-size: 20px;\n  position: absolute;\n  left: 20px;\n}\n\n@media only screen and (max-width: 767px) {\n  .CurrentDate_currentDate__hlgkC {\n    display: none;\n  }\n}\n\n/* For dark mode */\n[class=\"dark\"] .CurrentDate_currentDate__hlgkC {\n  border: 1px solid #373a40;\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/TopNavbar/CurrentDate.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,4BAA4B;EAC5B,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA,kBAAkB;AAClB;EACE,yBAAyB;AAC3B","sourcesContent":[".currentDate {\n  border: 1px solid #E2E8F0;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  color: var(--primaryColor);\n  padding: 10px 20px 10px 50px;\n  font-weight: 500; \n  font-size: 14px;\n  position: relative;\n}\n.currentDate i {\n  margin-right: 10px;\n  font-size: 20px;\n  position: absolute;\n  left: 20px;\n}\n\n@media only screen and (max-width: 767px) {\n  .currentDate {\n    display: none;\n  }\n}\n\n/* For dark mode */\n[class=\"dark\"] .currentDate {\n  border: 1px solid #373a40;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currentDate": "CurrentDate_currentDate__hlgkC"
};
export default ___CSS_LOADER_EXPORT___;
