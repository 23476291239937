

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import FormDetails from "../components/FormDetail/FormDetails"


function DistributorsFormDetailsModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/DistributorsFormDetails' element={<Layout children={FormDetails()}/>}/>
      </Routes>
    </div>
  );
}

export default DistributorsFormDetailsModule;
