import * as actionType from "../constants/actionTypes";

const subtitleReducer = (state = { subtitleData: null }, action) => {
	switch (action.type) {
		case actionType.SUBTITLE:
			return { ...state, subtitleDelete: action.payload };
			case actionType.AUDIOFILE:
				return { ...state, audioDelete: action.payload };
			
			case actionType.COUNTRYFILE:
			return { ...state, countryDelete: action.payload };
			
	
			
		default:
			return state;
	}
};

export default subtitleReducer;
