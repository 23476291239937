
import {
  MESSAGE, PROFILE,
  LOGGEDIN,
  MESSAGE1,
  MESSAGE2,
  MESSAGE3
} from '../constants/actionTypes';
import * as api from '../api/index.js';



export const login = (formData, navigate) => async (dispatch) => {

  try {

    const { data } = await api.login(formData);
    if (data?.statuscode == 200) {

      dispatch({ type: PROFILE, payload: data?.id });

      dispatch({ type: LOGGEDIN, payload: true });

      localStorage.setItem("loggedInDetails", JSON.stringify(data))
      localStorage.setItem("remember_me", formData?.remember_me)
      localStorage.setItem("loginDetails", JSON.stringify(formData))


      // if (data?.is_login_first_time == false) {

        navigate("/Dashboard", { state: { forceShow: true } })
      }
      // else
      //   navigate("/Authentication/ChangePassword", { state: { forceShow: true } })
      
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};

export const forgot_password = (formData) => async (dispatch) => {
  try {
    const { data } = await api.forgot_password(formData);
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};

export const Otp_verify = (formData) => async (dispatch) => {
  try {
    const { data } = await api.Otp_verify(formData);
    dispatch({ type: MESSAGE1, payload: data });
  } catch (error) {
    console.log(error)
  }
}

export const update_password = (formData) => async (dispatch) => {
  try {
    const { data } = await api.update_password(formData);
    dispatch({ type: MESSAGE2, payload: data });
  } catch (error) {
    console.log(error)
  }
}

export const change_password = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.change_password(formData);
    dispatch({ type: MESSAGE3, payload: data });
    localStorage.setItem("remember_me", "false")
    localStorage.setItem("loginDetails", "{}")
    localStorage.setItem("loggedInDetails", "{}")
    dispatch({ type: PROFILE, payload: {} });

    dispatch({ type: LOGGEDIN, payload: false });

    navigate("/", { state: { forceShow: true } })

  } catch (error) {
    console.log(error);
  }
};