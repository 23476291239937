import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import SubCategory from '../components/Masters/SubCategory/SubCategory';

function SubCategoryModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateSubCategory/' element={<Layout children={SubCategory()} />} />
                < Route path='/EditSubCategory' element={<Layout children={SubCategory()} />} />
                < Route path='/SubCategory' element={<Layout children={SubCategory()} />} />
            </Routes>
        </div>
    )
}
export default SubCategoryModule