import * as actionType from "../constants/actionTypes";

const watchhoursReducer = (state = { watchhoursData: null }, action) => {
	switch (action.type) {
		
		case actionType.WATCHHOURS:
			return {...state,watchhours: action.payload};
	
			
		default:
			return state;
	}
};

export default watchhoursReducer;
