import * as actionType from "../constants/actionTypes";

const merchandiseReducer = (state = { merchandiseData: null }, action) => {
	switch (action.type) {
		
	
			case actionType.NOTIFICATIONS:
			return {...state,notifications: action.payload};
			case actionType.NOTIFICATIONS_COUNT:
			return {...state,notifications_count: action.payload};
			
			case actionType.SLIDER_BANNERS:
			return {...state,slider_banners: action.payload};
	
			
		default:
			return state;
	}
};

export default merchandiseReducer;
