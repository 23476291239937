import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  movie_create,
  movie_delete,
  movie_update,
  all_movie_list,
} from "../../../actions/Movie/movie";
import {
  subtitle_delete,
  audio_file_delete,
} from "../../../actions/subtitle_delete";
import { all_category_list } from "./../../../actions/Masters/category";
import { all_subcategory_list } from "../../../actions/Masters/subcategory";
import { all_cast_list } from "../../../actions/Masters/cast";
import { all_genre_list } from "../../../actions/Masters/genre";
import {
  all_producer_list,
  producer_list_movie,
} from "../../../actions/producer";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";

import Import from "../../utils/Import";
import { useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import RemoveIcon from "@mui/icons-material/Remove";
import Alert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EnlargedView from "../../utils/EnlargedView";
import Popup from "../../utils/Popup";

export default function Movie() {
  const user = useSelector((state) => state.layout.profile);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [openAdError, setOpenAdError] = useState(false);
  const [open, setOpen] = useState(false);
  const [content, setPopupContent] = useState("");
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [flag, setFlag] = useState(false);
  const [result, setResult] = useState(undefined);
  const [count, setCount] = useState(0);

  // const [subtitlePosition, setSubtitlePosition] = useState();

  // const [pricePageShow, setPricePageShow] = useState(false);

  const [audioPosition, setAudioPosition] = useState();
  const [errorFields, setErrorFields] = useState({});
  const categories = useSelector((state) => state.masters.categories);

  const [open1, setOpen1] = useState(false);
  const [subTitleID, setsubTitleID] = useState("");

  const [audioFileId, setAudioFileId] = useState("");
  const [tableData, setTableData] = useState({
    ...TableData(),
    deleteRecord: movie_delete,
    onDeleteText: [
      "Are you sure want to delete this video ?",
      "The Customer Will not be able to see this video once you delete it.",
    ],
    customisedStatusUpdateMessage: true,
    onActiveText: "Are you sure want to Activate the video ?",
    onInactiveText: [
      "Are you sure want to Inactivate the video ?",
      "The Customer Will not be able to see this video once you Inactivate it.",
    ],
    updateRecord: movie_update,
  });

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  // const [open1, setOpen1] = useState(false);

  const [formTitle, setFormTitle] = useState(
    [
      "Movie Details",
      "Movie Resolutions",
      // "Audio Language",
      "Age Restrictions & Downloads",
      //  "Price Details",
    ].filter((e) => e)
  );
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "Movie" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        set_position: tableData.tableBody?.length + 1,
        // subtitles: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
        // movie_access: "TVOD",
        // audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditMovie");
  }, [location]);
  // console.log(location, "parthgg");
  const movies = useSelector((state) => state.movies.movies);
  // console.log(movies, "101010");


  useMemo(() => {
    if (movies?.statuscode == 200) {
      const temp = tableData;
      // const movieSubtitle = [];
      // movies?.data.map((value) => {
      //   // console.log(value, "value");
      //   const subtitle = {};
      //   value?.subtitle_list &&
      //     value?.subtitle_list?.map((ele, index) => {
      //       // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd");
      //       subtitle["subtitle_language_" + index] = ele?.subtitle_language;
      //       subtitle["subtitle_file_" + index] = ele?.subtitle_file;
      //       subtitle["subtitle_id_" + index] = ele?.id;
      //     });
      //   movieSubtitle.push(subtitle);
      // });

      // const movieAudio = [];
      // movies?.data.map((value) => {
      //   // console.log(value, "value");
      //   const Audio = {};
      //   value?.audio_file_list &&
      //     value?.audio_file_list?.map((ele, index) => {
      //       // console.log(index, "idd");
      //       Audio["audio_language_" + index] = ele?.audio_language;
      //       Audio["audio_file_" + index] = ele?.audio_file;
      //       Audio["audio_id_" + index] = ele?.id;
      //       // console.log(index, ele?.id, "12301230");
      //     });
      //   movieAudio.push(Audio);
      // });

      temp.tableBody = movies?.data.map((value, index) => ({
        ...value,
        // ...TVODdata[index],
        // ...movieSubtitle[index],
        // ...movieAudio[index],
        genre: value.movie_genre?.[0]?.["genre_title"],
        genre1: value.movie_genre?.[1]?.["genre_title"],
        movie_cast: value.movie_cast.map((cast) => cast.cast_name),
        movie_genre: value.movie_genre.map((genre) => genre.genre_title),
        distributor: value?.movie_distributor?.name,
        movie_ownership: value?.movie_ownership,
        rental_price:
          value?.rental_price == "null" || null || ""
            ? ""
            : value?.rental_price,
        streaming_fees:
          value?.streaming_fees == "null" || null || ""
            ? ""
            : value?.streaming_fees,
        available_for_days:
          value?.available_for_days == "null" || null || ""
            ? ""
            : value?.available_for_days,

        distributor_company: value?.movie_distributor?.company_name,
      }));

      setTableData({ ...temp });
      setForm({ ...form });
    }
  }, [movies]);
  // console.log(movies, "525252 ");

  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({
        // set_position: tableData.tableBody?.length + 1,
        allow_download: "Yes",
        show_notification: "Yes",
      });
    }

    const temp = formStructure;
    tempFormStruct.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );

    setFormStructure([...temp]);
  }, [isEdit]);
  const subcategories = useSelector((state) => state.masters.subcategories);
  const casts = useSelector((state) => state.masters.casts);
  const genre = useSelector((state) => state.masters.genre);
  const ProList = useSelector((state) => state?.producer.producermovielist);
  // const distributors = useSelector((state) => state.producer.producer);
  // console.log(subcategories, "okkko");

  const language = useSelector((state) => state.masters.languages);
  let count1=0
  useEffect(() => {
    count1++
    const data = new FormData();
    data.append("id", user?.id);
    if(movies?.statuscode!=200)
    dispatch(all_movie_list(data))
    if(subcategories?.statuscode!=200)
    dispatch(all_subcategory_list());
    if(categories?.statuscode!=200)
    dispatch(all_category_list());
    if(casts?.statuscode!=200)
    dispatch(all_cast_list());
    if(genre?.statuscode!=200)
    dispatch(all_genre_list());
    if(language?.statuscode!=200)
    dispatch(all_language_list());
    // dispatch(all_country_list());
    // dispatch(producer_list_movie());
    // dispatch(all_producer_list());
  }, [user?.id, isEdit]);

  // let count=0
  // useEffect(() => {
  //   count++

  //   if (user?.id) {
  //     const data = new FormData();
  //     data.append("id", user?.id);
  //     data.append("user",user?.id)
  // //  if(movies?.statuscode!=200)
  //       dispatch(all_movie_list(data));
        
  
  //     // dispatch(all_advertisement_list(data));
  //     if(subcategories?.statuscode!=200)
  //     dispatch(all_subcategory_list());
  //     if(countries?.statuscode!=200)
  //     dispatch(all_country_list());
  //     if(categories?.statuscode!=200)
  //     dispatch(all_category_list());
  //     if(casts?.statuscode!=200)
  //     dispatch(all_cast_list());
  //     if(genre?.statuscode!=200)
  //     dispatch(all_genre_list());
  //     if(Advisory?.statuscode!=200)
  //     dispatch(all_content_advisory_list());
  //     if(language?.statuscode!=200)
  //     dispatch(all_language_list());
  //     if(distributors?.statuscode!=200)
  //     dispatch(all_distributor_list(data));
    
  //   }
  // }, [user?.id,location]);


  // const handleAddSubtitle = (e) => {
  //   // console.log(e.currentTarget,"1111");
  //   const temp = formStructure;

  //   if (formStructure[3].length - 4 < language?.data.length * 3) {
  //     // console.log(form, "tr");

  //     const randomName = (formStructure[3].length - 4) / 3;
  //     // console.log(randomName, "101010");

  //     temp[3].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         title: "Select Language",
  //         size: "6",
  //         name: "subtitle_language_" + randomName,
  //         options: temp[3][5]["options"],
  //       },
  //       {
  //         id: "19",
  //         type: isEdit ? "lockedInput" : "inputBox",
  //         subtitle: "*",
  //         title: "Browse Subtitle file Link",
  //         size: "5.5",
  //         name: "subtitle_file_" + randomName,
  //         endsWith: ".srt",
  //         endsWith1: ".asc",

  //         errorText: "File extension should be .srt or .ASC only",
  //         required: true,
  //       },

  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,
  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: () => {
  //           setOpen(true);
  //           setSubtitlePosition(randomName);
  //           setsubTitleID(form["subtitle_id_" + randomName]);
  //           setPopupContent(
  //             "Do you want to remove this subtitle from this movie?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You can add max " + language?.data.length + " subtitles in a movie"
  //     );
  //   }
  // };
  // const handleAddAudio = (e) => {
  //   // console.log(e.currentTarget,"1111");
  //   const temp = formStructure;

  //   if (formStructure[2].length - 1 < language?.data.length * 3) {
  //     // console.log(form, "tr");

  //     const randomName = (formStructure[2].length - 1) / 3;
  //     // console.log(randomName, "11111");

  //     temp[2].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         title: "Select Language",
  //         size: "6",
  //         name: "audio_language_" + randomName,
  //         options: temp[2][2]["options"],
  //         // display:"none",
  //       },
  //       {
  //         id: "19",
  //         type: isEdit ? "lockedInput" : "inputBox",
  //         subtitle: "*",
  //         title: "Browse mp3 file link",
  //         name: "audio_file_" + randomName,
  //         size: "5.5",
  //         endsWith: ".wav",
  //         endsWith1: ".mp3",
  //         errorText: "File extension should be .mp3 or .wav only",
  //         required: true,
  //         // display:"none",
  //       },

  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,
  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: () => {
  //           setOpen(true);
  //           setAudioPosition(randomName);
  //           setAudioFileId(form["audio_id_" + randomName]);
  //           setPopupContent(
  //             "Do you want to remove this audio file from this movie?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You can add max " + language?.data.length + " audio file in a movie"
  //     );
  //   }
  // };

  useMemo(() => {
    if (isEdit) {
      setView("create_new");

      // if (form?.subtitle_list.length > (formStructure?.[3].length - 4) / 3)
      //   form?.subtitle_list.map(
      //     (ele) =>
      //       form?.subtitle_list.length > (formStructure?.[3].length - 4) / 3 &&
      //       handleAddSubtitle()
      //   );

      // if (form?.audio_file_list.length > (formStructure?.[2].length - 1) / 3)
      //   form?.audio_file_list.map(
      //     (ele) =>
      //       form?.audio_file_list.length >
      //         (formStructure?.[2].length - 1) / 3 && handleAddAudio()
      //   );
    } else {
      setView("view_all");
      setForm({
        set_position: tableData.tableBody.length + 1,
        // subtitles: "Inactive",

        // audio_language: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
        // movie_access: "TVOD",
      });
    }
    const temp = formStructure;
    temp.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    // temp[2][1]["handleClick"] = handleAddAudio;
    // temp[4][0]["handleClick"] = handleClick;
    // temp[3][4]["handleClick"] = handleAddSubtitle;

    setFormStructure([...temp]);
  }, [isEdit, language, location]);

  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][8]["display"] = "block";
      temp[0][9]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][8]["display"] = "none";
      temp[0][9]["display"] = "none";
      setFormStructure([...temp]);
    }
    if (form?.movie_access == "TVOD") {
      const temp = formStructure;
      temp[0][10]["display"] = "block";
      // temp[0][11]["display"] = "block";
      // temp[0][12]["display"] = "block";
      // temp[0][13]["display"] = "block";
      temp[0][11]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][10]["display"] = "none";
      // temp[0][11]["display"] = "none";
      // temp[0][12]["display"] = "none";
      // temp[0][13]["display"] = "none";
      temp[0][11]["display"] = "none";
      setFormStructure([...temp]);
    }
    if (form?.movie_access == "SVOD") {
      const temp = formStructure;
      temp[0][12]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][12]["display"] = "none";
      setFormStructure([...temp]);
    }
    // if (form?.subtitles == "Active") {
    //   const temp = formStructure;
    //   temp[3].map((ele, index) => index >= 4 && (ele["display"] = "block"));
    //   setForm({ ...form, subtitle_count: 1 });
    //   setFormStructure([...temp]);
    // } else {
    //   const temp = formStructure;
    //   temp[3].map((ele, index) => index >= 4 && (ele["display"] = "none"));
    //   setFormStructure([...temp]);
    // }

    // if (form?.audio_language == "Active") {
    //   const temp = formStructure;
    //   temp[2].map((ele, index) => index >= 1 && (ele["display"] = "block"));
    //   setForm({ ...form });
    //   setFormStructure([...temp]);
    // } else {
    //   const temp = formStructure;
    //   temp[2].map((ele, index) => index >= 1 && (ele["display"] = "none"));
    //   setFormStructure([...temp]);
    // }
  }, [
    form?.publish_type,
    form?.movie_access,
    // form?.subtitles,
    // form?.audio_language,
  ]);
  // useMemo(() => {
  //   if (form?.rental_price != "") {
  //     setForm({
  //       ...form,
  //       amount_received:
  //         (form?.rental_price -
  //         form?.streaming_fees -
  //         Number(form?.rental_price) * 0.185).toFixed(2),
  //       tax: (Number(form?.rental_price) * 0.185).toFixed(2),
  //     });
  //   }
  // }, [form?.rental_price, form?.streaming_fees]);
  // useMemo(() => {
  //   if (form?.amount_received < 0) {
  //     setOpenAdError(true);
  //     setPopupContent("Your receivable amount goes into Minus(-)");
  //   }
  // }, [form?.amount_received]);

  // useMemo(() => {
  //   if (form?.amount_received0 < 0) {
  //     setOpenAdError(true);
  //     setPopupContent("Your receivable amount goes into Minus(-)");
  //   }
  // }, [form?.amount_received0]);
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[0][5]["options"] = genre?.data.map((genre) => genre?.genre_title);

      setFormStructure([...temp]);
    }
  }, [genre]);

  useMemo(() => {
    if (categories?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tempFilter });
    }
  }, [categories]);

  // console.log(countries,"parthhh")

  useMemo(() => {
    if (subcategories?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = subcategories?.data;

      if (form?.movie_category != undefined) {
        temp[0][1]["options"] = subcategories?.data
          .map(
            (subcategory) =>
              subcategory?.category_name == form?.movie_category &&
              subcategory?.subcategory_name
          )
          .filter((e) => e);
        tempForm.movie_subcategory = temp[0][1]["options"].includes(
          form?.movie_subcategory
        )
          ? form?.movie_subcategory
          : "";
      } else {
        temp[0][1]["options"] = subcategories?.data.map(
          (subcategory) => subcategory?.subcategory_name
        );
      }
      setFormStructure([...temp]);
      setForm({ ...tempForm });
      setTableData({ ...tempFilter });
    }
  }, [subcategories, form?.movie_category]);
  useMemo(() => {
    if (casts?.statuscode == 200) {
      const temp = formStructure;
      temp[0][6]["options"] = casts?.data.map((cast) => cast?.cast_name);

      setFormStructure([...temp]);
    }
  }, [casts]);

  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[0][4]["options"] = language?.data.map(
        (language) => language?.language_name
      );
      // temp[3][5]["options"] = language?.data.map(
      //   (language) => language?.language_name
      // );
      // temp[2][2]["options"] = language?.data.map(
      //   (language) => language?.language_name
      // );
      setFormStructure([...temp]);
      const tempFilter = tableData;
      tempFilter["filterColumn"][0]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempFilter });
    }
  }, [language]);

  const message = useSelector((state) => state.layout.message);
  // console.log(message, "7578575");
  const handleSubmit = (event) => {
    // console.log(form,"oiio")
    event.preventDefault();
    const data = new FormData();
    const updatedErrorFields = {};

    FormStructure(isEdit).forEach((value) => {
      // Check if the field is required and empty
      if (value.required && !form[value.name]) {
        updatedErrorFields[value.name] = true;
      }
    });

    setErrorFields(updatedErrorFields);

    // Proceed with form submission if there are no error fields
    if (Object.keys(updatedErrorFields).length === 0) {
      // Submit the form data using API calls, etc.

      const temp_movie_category = form;
      temp_movie_category["tempmovie_subcategory"] =
        form?.["movie_subcategory"];
      temp_movie_category["tempmovie_category"] = form?.["movie_category"];
      temp_movie_category["tempmovie_cast"] = form?.["movie_cast"];
      temp_movie_category["tempmovie_genre"] = form?.["movie_genre"];
      temp_movie_category["tempmovie_language"] = form?.["movie_language"];
      // temp_movie_category["distributor"] = distributors?.data
      //   .map(
      //     (option) => form["distributor"]?.includes(option?.name) && option.id
      //   )
      //   .filter((e) => e);
      temp_movie_category["movie_subcategory"] = subcategories?.data
        .map(
          (option) =>
            form?.["movie_category"] === option?.category?.category_name &&
            form?.["movie_subcategory"]?.includes(option.subcategory_name) &&
            option.id
        )
        .filter((e) => e);
      temp_movie_category["movie_category"] = categories?.data
        .map(
          (option) =>
            form?.["movie_category"]?.includes(option.category_name) &&
            option.id
        )
        .filter((e) => e);
      temp_movie_category["movie_cast"] = casts?.data
        .map(
          (option) =>
            form?.["movie_cast"]?.includes(option.cast_name) && option.id
        )
        .filter((e) => e);

      temp_movie_category["movie_genre"] = genre?.data
        .map(
          (option) =>
            form?.["movie_genre"]?.includes(option.genre_title) && option.id
        )
        .filter((e) => e);

      temp_movie_category["movie_language"] = language?.data
        .map(
          (option) =>
            form?.["movie_language"]?.includes(option.language_name) &&
            option.id
        )
        .filter((e) => e);

      temp_movie_category["created_by"] = user?.id;
      setForm({
        ...temp_movie_category,
      });

      Object.keys(form).map((key) => data.append(key, form?.[key]));
      const temp_form1 = form;
      temp_form1["movie_subcategory"] = form?.["tempmovie_subcategory"];
      temp_form1["movie_category"] = form?.["tempmovie_category"];
      temp_form1["movie_cast"] = form?.["tempmovie_cast"];
      temp_form1["movie_genre"] = form?.["tempmovie_genre"];
      temp_form1["movie_language"] = form?.["tempmovie_language"];
      temp_form1["distributor"] = form?.["tempdistributor"];
      setForm({
        ...temp_form1,
      });

      const temp = tableData;
      if (isEdit) {
        dispatch(movie_update(data));
      } else {
        dispatch(movie_create(data));
      }
    }
    setFlag(true);
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);

      setForm({
        set_position: tableData.tableBody.length + 1,
        // subtitles: "Inactive",
        // audio_language: "Inactive",
        movie_is_for18Plus: "No",
        allow_download: "Yes",
        status: "Active",
      });

      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        dispatch(all_movie_list(data));
      }, 900);
      navigate("/Movie/Movie", { state: { view: "view_all" } });
      // setView("view_all");
    } else {
      setForm({ ...form });
    }
  }, [message]);
  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, movie_duration: hDisplay + mDisplay + sDisplay });
  };
  // useMemo(()=>{
  //   if(form?.rental_price0){
  //     const temp = form
  //     temp["country0"] = "United States"
  //     temp["co_tax0"] =  countries?.data
  //     ?.map(
  //       (ele, index) =>
  //         form?.["country0"] == ele?.country_name &&
  //         ele?.country_tax
  //     )
  //     .filter((e) => e);
  //     // temp["amount_after_deduction0"] = (
  //     //   form?.["rental_price0"] -
  //     //   Number(form?.["rental_price0"]) *
  //     //     Number(form?.["co_tax0"] / 100)
  //     // ).toFixed(2);
  //     // temp["tax0"] = (
  //     //   Number(form?.["rental_price0"]) *
  //     //   Number(form?.["co_tax0"] / 100)
  //     // ).toFixed(2);
  //     setForm({...form})
  //   }
  // },[form?.rental_price0 ,countries])

  // useMemo(() => {
  //   if (result) {
  //     const temp = formStructure;
  //     //  if (subtitlePosition != undefined) {
  //     //   temp[3].map((value, index) => {
  //     //     if (value.position == subtitlePosition) {
  //     //       const tempForm = form;
  //     //       tempForm["subtitle_language_" + value.position] = "";
  //     //       tempForm["subtitle_file_" + value.position] = "";

  //     //       setForm({ ...tempForm });
  //     //       temp[3].splice(index - 2, 3);
  //     //     }
  //     //   });
  //     //   setFormStructure([...temp]);
  //     //   setSubtitlePosition(undefined);

  //     //   dispatch(subtitle_delete({ id: subTitleID, video_type: "Movie" }));
  //     //   // console.log(form, "idssss");
  //     // }
  //     if (subtitlePosition != undefined) {
  //       var scount = 1;
  //       if (!temp[3][temp[3].length - 1]["isLast"]) {
  //         temp[3].map((value, index) => {
  //           if (value.position >= subtitlePosition) {
  //             console.log(
  //               value.position,
  //               subtitlePosition,
  //               !temp[3][temp[3].length - 1]["isLast"],
  //               "8888"
  //             );
  //             const tempForm = form;
  //             if (index < temp[3].length - 3) {
  //               tempForm["subtitle_file_" + value.position] =
  //                 tempForm["subtitle_file_" + (Number(value.position) + 1)];
  //               tempForm["subtitle_language_" + value.position] =
  //                 tempForm["subtitle_language_" + (Number(value.position) + 1)];
  //             } else {
  //               tempForm["subtitle_file_" + value.position] = "";
  //               tempForm["subtitle_language_" + value.position] = "";
  //             }

  //             setForm({ ...tempForm });
  //           }
  //         });

  //         temp[3].map((value, index) => {
  //           if (value.type == "button" && index > 7) {
  //             temp[3][index - 1]["name"] = "subtitle_file_" + scount;
  //             temp[3][index - 2]["name"] = "subtitle_language_" + scount;
  //             temp[3][index]["position"] = scount;
  //             // if(index == temp[2].length -1 ){
  //             //   temp[2][index]["isLast"] = true

  //             // }
  //             scount++;
  //           }
  //         });
  //         console.log(temp[3], temp[3].length, "testing4");
  //         if (!temp[3][temp[3].length - 1]["isLast"]) {
  //           temp[3].splice(temp[3].length - 3, 3);
  //         }
  //         temp[3][temp[3].length - 1]["isLast"] = true;
  //         // setFormStructure([...temp]);
  //         console.log(formStructure, "newFormSt");
  //         setFormStructure([...temp]);
  //       } else temp[3][temp[3].length - 1]["isLast"] = false;
  //       setSubtitlePosition(undefined);

  //       dispatch(subtitle_delete({ id: subTitleID, video_type: "Movie" }));
  //       // console.log(form, "idssss")
  //     }
  //     // else if (audioPosition != undefined) {
  //     //   temp[2].map((value, index) => {
  //     //     if (value.position == audioPosition) {
  //     //       // console.log(value.position, "8888");
  //     //       const tempForm = form;

  //     //       tempForm["audio_file_" + value.position] = "";
  //     //       tempForm["audio_language_" + value.position] = "";

  //     //       setForm({ ...tempForm });
  //     //       temp[2].splice(index - 2, 3);
  //     //     }
  //     //   });
  //     //   setFormStructure([...temp]);
  //     //   setAudioPosition(undefined);
  //     //   dispatch(audio_file_delete({ id: audioFileId, video_type: "Movie" }));
  //     // }
  //     else if (audioPosition != undefined) {
  //       var count = 1;
  //       temp[2].map((value, index) => {
  //         if (
  //           value.position >= audioPosition &&
  //           !temp[2][temp[2].length - 1]["isLast"]
  //         ) {
  //           console.log(
  //             value.position,
  //             audioPosition,
  //             !temp[2][temp[2].length - 1]["isLast"],
  //             "8889"
  //           );
  //           // console.log(value.position, "8888")]
  //           const tempForm = form;
  //           if (index < temp[2].length - 3) {
  //             tempForm["audio_file_" + value.position] =
  //               tempForm["audio_file_" + (Number(value.position) + 1)];
  //             tempForm["audio_language_" + value.position] =
  //               tempForm["audio_language_" + (Number(value.position) + 1)];
  //           } else {
  //             tempForm["audio_file_" + value.position] = "";
  //             tempForm["audio_language_" + value.position] = "";
  //           }

  //           setForm({ ...tempForm });
  //         }
  //       });

  //       temp[2].map((value, index) => {
  //         if (value.type == "button" && index > 3) {
  //           temp[2][index - 1]["name"] = "audio_file_" + count;
  //           temp[2][index - 2]["name"] = "audio_language_" + count;
  //           temp[2][index]["position"] = count;
  //           // if(index == temp[2].length -1 ){
  //           //   temp[2][index]["isLast"] = true

  //           // }
  //           count++;
  //         }
  //       });
  //       console.log(temp[2], temp[2].length, "testing3");
  //       if (!temp[2][temp[2].length - 1]["isLast"]) {
  //         temp[2].splice(temp[2].length - 3, 3);
  //       }
  //       temp[2][temp[2].length - 1]["isLast"] = true;
  //       setFormStructure([...temp]);

  //       setAudioPosition(undefined);
  //       dispatch(audio_file_delete({ id: audioFileId, video_type: "Movie" }));
  //     }
  //   }
  //   setResult(undefined);
  // }, [result]);

  const handleClose = () => {
    setOpenAdError(false);
  };
  // useEffect(() => {
  //   const temp = formStructure;
  //   // temp[4][0]["handleClick"] = handleClick;
  //   temp[3][4]["handleClick"] = handleAddSubtitle;
  //   temp[2][1]["handleClick"] = handleAddAudio;
  //   setFormStructure([...temp]);
  // }, []);
  // const subtitles = language?.data?.map((ele, index) =>
  //   eval("form?.subtitle_language_" + index)
  // );
  // const audio_language = language?.data?.map((ele, index) =>
  //   eval("form?.audio_language_" + index)
  // );

  // useMemo(() => {
  //   //   console.log("new code123");
  //   //   const temp = formStructure;

  //   //   setFormStructure([...temp]);

  //   // console.log(dependancy_list, "7885555");
  //   const newLanguageList = language?.data
  //     ?.map(
  //       (ele, index) =>
  //         !subtitles.includes(ele?.language_name) && ele?.language_name
  //     )
  //     .filter((e) => e);
  //   const newAudioLanguage = language?.data
  //     ?.map(
  //       (ele, index) =>
  //         !audio_language.includes(ele?.language_name) && ele?.language_name
  //     )
  //     .filter((e) => e);

  //   const temp = formStructure;
  //   temp[3].map(
  //     (ele, index) =>
  //       ele?.type == "select" && (temp[3][index]["options"] = newLanguageList)
  //   );
  //   temp[2].map(
  //     (ele, index) =>
  //       ele?.type == "select" && (temp[2][index]["options"] = newAudioLanguage)
  //   );

  //   setFormStructure([...temp]);
  // }, [form]);

  console.log(view, "new View");

  return (
    <>
      {/* <Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

				open={open1}
			>
				<CircularProgress color="inherit" />
			</Backdrop> */}
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.movieMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>

      {/* <EnlargedView
				open={openApproval}
				setOpen={setOpenApproval}
				content={content}
			/> */}
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Movie/CreateMovie"}
        view_all={"/Movie/Movie"}
        form={form}
        export_excel={
          <Export
            fileName={"Movies"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Movie/EditMovie"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
