import * as actionType from "../constants/actionTypes";

const mastersReducer = (state = { mastersData: null }, action) => {
	switch (action.type) {
		
		case actionType.CATEGORIES:
			return {...state,categories: action.payload};
			case actionType.SUBCATEGORIES:
			return {...state,subcategories: action.payload};
			case actionType.CASTS:
			return {...state,casts: action.payload};
			
			case actionType.GENRE:
			return {...state,genre: action.payload};

			case actionType.LANGUAGES:
			return {...state,languages: action.payload};
			case actionType.COUNTRY:
			return {...state,country: action.payload};
			
			
		default:
			return state;
	}
};

export default mastersReducer;
