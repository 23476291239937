

import { Routes, Route } from 'react-router-dom';
import SignInForm from '../components/Authentication/SignInForm';
import ChangePassword from '../components/Authentication/ChangePassword';
import ForgotPasswordForm from '../components/Authentication/ForgotPasswordForm';
import OtpForm from '../components/Authentication/OtpForm';
import UpdatePassword from '../components/Authentication/UpdatePassword';
function AuthenticationModule() {
  return (
    <div className="App">

      <Routes>
        {/* Test */}

        {/* test */}
        < Route path='/SignIn' element={<SignInForm key="SignInForm" />} />
        < Route path='/ChangePassword' element={<ChangePassword key="ChangePassword" />} />
        < Route path='/UpdatePassword' element={<UpdatePassword key="UpdatePassword" />} />

        < Route path='/ForgotPassword' element={<ForgotPasswordForm key="ForgotPasswordForm" />} />
        < Route path='/' element={<SignInForm key="SignInForm" />} />
        <Route path='/Otp' element={<OtpForm key="OtpForm" />} />
      </Routes>
    </div>
  );
}

export default AuthenticationModule;
