import React from "react";
import AddIcon from "@mui/icons-material/Add";
export const FormStructure = (isEdit) => {
  return [
    [
      {
        id: "1",
        type: "inputBox",
        title: "Production Company Name",
        name: "production_name",
        regex: /^[a-zA-Z0-9\s\&]+$/,
        // required: true,
      },
      {
        id: "2",
        type: "inputBox",
        title: "distribution Company Name",
        name: "distribution_name",
        regex: /^[a-zA-Z0-9\s\&]+$/,

      
      },
      {
        id: "3",
        type: "inputBox",
        title: "GST [For India] OR Any VAT/Taxation details",
        name: "GST",
        errorText: "3.	GST [For India] OR Any VAT/Taxation details",
       
      },
      {
        id: "4",
        type: "select",
        name: "category_type",
        title: "Select Category",
        options: ["Movie", "Web Series"],
        // required: true,
      },
      {
        id: "4",
        type: "select",
        title: "Type of Film",
        name: "type_of_film",
        options: [
          "Feature",
          "Short",
          "Documentary",
          "Animation",
          "Knowledge Based",
          "Spiritual",
          "Religious",
        ],
        // required: true,
      },
      {
        id: "4",
        type: "select",
        title: "Type of Web Series",
        name: "type_of_series",
        options: ["Mini Web Series", "Super Short Series"],
        // required: true,
        display: "none",
      },

      {
        id: "2",
        type: "select",
        title: "Season",
        name: "season",
        options: [
          "Season 1",
          "Season 2",
          "Season 3",
          "Season 4",
          "Season 5",
          "Season 6",
          "Season 7",
          "Season 8",
          "Season 9",
          "Season 10",
        ],
        // required: true,
        display: "none",
      },
      {
        id: "2",
        type: "inputBox",
        title: "Episode No",
        name: "episode_number",
        variant: "number",
        min: "1",
        max: "5",
        maxLength: "2",
        // required: true,
        display: "none",
      },
      {
        id: "5",
        type: "inputBox",
        title: "Title",
        name: "title",
        // required: true,
      },
      {
        id: "6",
        type: "select",
        title: "Original Language",
        name: "language ",
        options: [
          "Feature",
          "Short",
          "Documentary",
          "Animation",
          "Knowledge Based",
          "Spiritual",
          "Religious",
        ],
        // required: true,
      },
      {
        id: "7",
        type: "select_multiple",
        title: "Genre",
        name: "genre",
        maxSelections: "5",
        errorText: "You can select max 5 genre at a time",
        options: [
          "Feature",
          "Short",
          "Documentary",
          "Animation",
          "Knowledge Based",
          "Spiritual",
          "Religious",
        ],
        // required: true,
      },
      {
        id: "4",
        type: "inputBox",
        title: "Country of Origin",
        name: "country_of_origin",
        // required: true,
        regex: /^[a-zA-Z\s\&]+$/,
      },
      {
        id: "4",
        type: "inputBox",
        title: "Producer",
        regex: /^[a-zA-Z\s\&]+$/,

        name: "producer",
        // required: true,
      },
      {
        id: "4",
        type: "inputBox",
        title: "Director",
        regex: /^[a-zA-Z\s\&]+$/,

        name: "director",
        // required: true,
      },
      {
        id: "4",
        type: "inputBox",
        title: "Cast",
        name: "cast",
        regex: /^[a-zA-Z\s\&]+$/,

        // required: true,
      },

      {
        id: "3",
        type: "inputBox",
        title: "Year of Production & Completion",
        name: "year_of_production",
        regex: /^[0-9\s\&]+$/,

        // required: true,
      },
    
      {
        id: "3",
        type: "inputBox",
        title: "Festival Awards",
        name: "festival_awards",
        regex: /^[a-zA-Z0-9\s\&]+$/,

        // required: true,
      },
      {
        id: "3",
        type: "inputBox",
        title: "Rights Available",
        regex: /^[a-zA-Z0-9\s\&]+$/,
        name: "rights_available",
        // required: true,
      },
      {
        id: "3",
        type: "inputBox",
        title: "Contact Person's Name",
        name: "contact_person_name",
        regex: /^[a-zA-Z\s\&]+$/,

        // required: true,
      },
      {
        id: "3",
        type: "inputBox",
        title: "Email",
        variant: "email",
        name: "email",
        // required: true,
      },
      {
        id: "4",
        type: "phone",
        title: "Mobile Number",
        maxLength: 12,
        name: "mobileNumber",
        // required: true,
      },
      {
        id: "24",
        type: "inputBox",
        // size: "1.5",
        title: "Expected Rent Amount",
        name: "rent",
        maxLength: "3",
        regex: /^[0-9\s\&]+$/,

        // required: true,
      },
      
    ],
    [
      {
        id: "1",
        type: "select",
        title: "Original Shooting Format",
        name: "original_shooting_format",
        options: ["4K", "2K" , "HD" , "OTHER"],

        // required: true,
      },
      {
        id: "1",
        type: "select",
        title: "Sound Format Required [Stereo]:",
        name: "sound_format_required",
        options: ["Dolby 5.1", "Stereo"],
        // required: true,
      },

      {
        id: "15",
        type: "select",
        title: "Required Format",
        name: "mp4_URL",
        options: ["High Res 2K QT", "2K MP4"],
        // required: true,
      },
      {
        id: "15",
        type: "inputBox",
        title: "Trailer Link",
        endsWith: ".123",
        errorText:
        "You can Upload your movie/video file to Google Drive, VIMEO, WETRANSFER, or any other online platform. After you upload the film, a link will be generated, which you must copy and paste into the text box above.",
        name: "trailer_link",
        // required: true,
      },
      {
        id: "15",
        type: "inputBox",
        title: "Content File",
        endsWith: ".0",
        errorText:
          "You can Upload your movie/video file to Google Drive, VIMEO, WETRANSFER, or any other online platform. After you upload the film, a link will be generated, which you must copy and paste into the text box above.",
        name: "content_file",
        // required: true,
      },
      {
        id: "15",
        type: "inputBox",
        title: "IMDB | Facebook Page",
        // endsWith: ".mp4",
        // errorText: "File extension should be .mp4 only",
        name: "imdb_facebook_url",
     
      },
      {
        id: "22",
        type: "toggle",
        title: "Released Status",
        name: "movie_released_status",
        default: "Unreleased",
        size: "12",
        options: [
          { value: "Released", color: "success" },
          { value: "Unreleased", color: "danger" },
        ],
      },
      {
        id: "5",
        type: "inputBox",
        variant: "date",
        title: "Theatrical Date",
        name: "theatrical_date",
        min: new Date().toISOString().split("T")[0],
        display:"none"

      },
      {
        id: "5",
        type: "inputBox",
        variant: "date",
        title: "Proposed Premiere Date",
        name: "proposed_premiere_date",
        min: new Date().toISOString().split("T")[0],
    
      },
      {
        id: "5",
        type: "inputBox",
        variant: "date",
        title: "Proposed Release Date",
        name: "proposed_release_date",
        min: new Date().toISOString().split("T")[0],
        // required: true,
      },
    ],
    [
      {
        id: "23",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
        // display:"none",
      },
      {
        id: "21",
        type: "select",
        title: "Select Language",
        size: "6",
        name: "audio_language_0",
        options: ["Advertise 1", "Advertise 2"],
        // display:"none",
      },
      {
        id: "19",
        type: "inputBox",
        subtitle: "*",
        title: "Upload wav file",
        name: "audio_file_0",
        size: "5.5",
        endsWith: ".wav",
        errorText: "File extension should be .wav only",
        // required: true,
        // display:"none",
      },
    ],
    [
      {
        id: "23",
        type: "button",
        title: <AddIcon sx={{ color: "#fff !important" }} />,
        align: "left",
        forceShow: true,
        // display: "none"
      },
      {
        id: "22",
        type: "select",
        title: "Select Language",
        size: "6",
        name: "subtitle_language_0",
        options: ["Advertise 1", "Advertise 2"],
        // display: "none"
      },
      {
        id: "28",
        type: "inputBox",
        subtitle: "*",
        title: "Browse Subtitle file(.srt)",
        name: "subtitle_file_0",
        size: "5.5",
        endsWith: ".srt",
        errorText: "File extension should be .srt only",

        // required: true,
        // display: "none"
      },
    ],
  ];
};
