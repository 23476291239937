
import { MESSAGE, CUSTOMERS, CUSTOMER,ACTCUSTOMERS } from './../constants/actionTypes';
import * as api from './../api/index.js';




export const customer_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.customer_update(formData);
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};
export const customer_delete = (formData) => async (dispatch) => {
  try {
    const { data } = await api.customer_delete(formData);
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};
export const all_customer_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.all_customer_list(formData);

    dispatch({ type: CUSTOMERS, payload: data });


  } catch (error) {
    console.log(error);
  }
};

export const active_user_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.active_user_list(formData);
    dispatch({ type: ACTCUSTOMERS, payload: data })
  } catch (error) {
    console.log(error)
  }
}

export const customer_details = (formData) => async (dispatch) => {
  try {
    const { data } = await api.customer_details(formData);

    dispatch({ type: CUSTOMER, payload: data });


  } catch (error) {
    console.log(error);
  }
};
