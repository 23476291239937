import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import graph from "./../../images/graph-icon.png"
import work from "./../../images/work-icon.png"
import { Link } from "react-router-dom";


const Features = ({data}) => {
  console.log(data,"0000000000")

  const FeaturesData = [
   
    {
      id: "2",
      subTitle: "Videos Watched",
      title: data?.watch_count,
      image: graph,
      link:"/Customer/CustomerDetail/MoviesWatched",
      data:data?.movie_watch_list
    },
    {
      id: "3",
      subTitle: "Downloaded",
      title: data?.download_count,
      image: work,
      link:"/Customer/CustomerDetail/MoviesDownload",
      data:data?.download
    },
    {
      id: "3",
      subTitle: "Rented Movies",
      title: data?.rental_movie_count,
      image: work,
      link:"/Customer/CustomerDetail/MoviesRented",
      data:data?.rental_movie_list
    }
  ];
  return (
    <>
      <Grid
        container
        justifyContent="center"
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        {FeaturesData.map((feature) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={feature.id}>
            <Link to={feature.link} state={{data:feature.data}}>
            <Card
              sx={{
                boxShadow: "var(--gradientColorLightest2) 0px 6px 12px -2px, var(--gradientColorLightest1) 0px 3px 7px -3px",
                borderRadius: "10px",
                p: "30px 10px",
                mb: "15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"Center"
                }}
              >
              

                <Box sx={{
                    
                    textAlign: "center",
                  }}>
                  <Typography variant="p" sx={{ fontSize: '13px',textAlign:"Center" }}>
                    {feature.subTitle}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{ fontSize: 28, fontWeight: 700, mt: "5px",textAlign:"Center" }}
                  >
                    {feature.title}
                  </Typography>
                </Box>
              </Box>
            </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Features;
