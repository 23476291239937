

import {  Routes, Route } from 'react-router-dom';
import Complaint from '../components/Complaint/Complaint';
import Layout from '../components/Layout/Layout';

function ComplaintModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Complaint' element={<Layout children={Complaint()}/>}/>


      </Routes>
    </div>
  );
}

export default ComplaintModule;