import React from "react";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "./../../styles/PageTitle.module.css";
import ListTable from "../utils/Table";
export default function MoviesRented() {
  const location = useLocation();
  const [tableData, setTableData] = useState({
    tableTitle: "Movies Rented",
    disableDelete: true,
    tableHead: [
      {
        id: "name",
        label: "Movie/Episode Name",
        // isSpecial: true,
        subText : "movie_duration",
        align: "left",
      },
      // {
      //   id: "watch_hours",
      //   label: "Watchhours",
      // },
      {
        id: "created_at",
        label: "Last Watched on",
      },
      {
        id: "profile_name",
        label: "Watched By",
      },
      {
        id: "device_id",
        label: "Device Name",
        subText:"device_type"
      },
      {
        id: "location",
        label: "Location",
      },
    ],
    tableBody: [],
  });
  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  console.log(location?.state?.data,"newLocations")
  useMemo(() => {
    if (location?.state?.data) {
      const temp = tableData;
      temp.tableBody = location.state?.data?.map((ele) => ({
        ...ele,
        name:
          ele?.movie !== null
            ? ele?.movie?.movie_name
            : ele?.series?.series_name,
            movie_duration:
            ele?.movie !== null
              ? ele?.movie?.movie_duration
              : "-",
              profile_name:ele?.user_data?.watch_by
      }));
      setTableData({ ...temp });
    }
  }, [location?.state?.data]);
  return (
    <>
      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />
    </>
  );
}
