import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Series from "../components/WebSeries/Series/Series";
import ProductDetailsContent from "../components/SeriesDetails/ProductDetailsContent";

function SeriesModule() {
  return (
    <div className="App">
      <Routes>
        <Route path="/Series" element={<Layout children={Series()} />} />
        <Route path="/CreateSeries" element={<Layout children={Series()} />} />
        <Route path="/EditSeries" element={<Layout children={Series()} />} />
        <Route
          path="/Series/SeriesDetails"
          element={<Layout children={ProductDetailsContent()} />}
        />
      </Routes>
    </div>
  );
}

export default SeriesModule;
