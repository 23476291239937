
import { MESSAGE,SLIDER_BANNERS } from '../constants/actionTypes';
import * as api from '../api/index.js';


export const sliderbanner_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.sliderbanner_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const sliderbanner_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.sliderbanner_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const sliderbanner_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.sliderbanner_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_sliderbanner_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_sliderbanner_list(formData);
      
      dispatch({ type: SLIDER_BANNERS, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  