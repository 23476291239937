import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Category from '../components/Masters/Category/Category';

function CategoryModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateCategory' element={<Layout children={Category()} />} />
                < Route path='/EditCategory' element={<Layout children={Category()} />} />
                < Route path='/Category' element={<Layout children={Category()} />} />
            </Routes>
        </div>
    )
}
export default CategoryModule