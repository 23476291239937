import * as actionType from "../constants/actionTypes";

const mastersReducer = (state = { mastersData: null }, action) => {
	switch (action.type) {
		
		

			case actionType.REFUNDPOLICY:
			return {...state,refundpolicy: action.payload};
			case actionType.ABOUTUS:
			return {...state,aboutus: action.payload};

			case actionType.TERMSCONDITIONS:
			return {...state,termsconditions: action.payload};
			case actionType.PRIVACYPOLICY:
			return {...state,privacypolicy: action.payload};
			
		default:
			return state;
	}
};

export default mastersReducer;
