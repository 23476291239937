import React from "react";
import { Stack, Box, Typography, Link } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Stack
        sx={{
         
          // backgroundColor: "#fff",
          p: "25px",
          borderRadius: "10px 10px 0 0",
          textAlign: "center",
          mt: "15px"
        }}
        className="footer"
      >
        <Box >
          <Typography>
          Copyright © {new Date().getFullYear()} <a href="https://icode49.com/" target="_blank" style={{ color: "inherit", fontWeight: "600", marginLeft: "4px", marginRight: "4px" }}>iCode49 Technolabs.</a>  all rights reserved
     

          
          </Typography>
        </Box>
      </Stack>
    </>
  );
};

export default Footer;
