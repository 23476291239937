import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import {
	
	all_country_list,
} from "../../../actions/Masters/country";
import { useDispatch, useSelector } from "react-redux";
export default function Country() {
	const dispatch = useDispatch();
	
	const [isEdit, setIsEdit] = useState(false);
	const [form, setForm] = useState({});
	
	const tempTableData={
		tableTitle: "Countries",
		tableHead: [
			{
				id: "country_name",
				label: "Country",
			},
			{
				id: "country_currency_symbol",
				label: "Currency Symbol",
			},
			
			
		],
		tableBody: [
			
		],
		
	}
	const [tableData, setTableData] = useState({...tempTableData});
	


	const countries = useSelector((state) => state.masters.country);
	console.log(countries,"new Count")
	useEffect(() => {
		dispatch(all_country_list());
	}, []);
	useMemo(() => {
		if (countries?.statuscode == 200) {
			const temp = tableData;
			temp.tableBody = countries?.data;
			setTableData({ ...temp });
		}
	}, [countries]);

	
	
	return (
		<>
			
				<ListTable
					tableData={tableData}
					key={"ListTable"}
					setForm={setForm}
					setTableData={setTableData}
					setIsEdit={setIsEdit}
				/>
		
		</>
	);
}
