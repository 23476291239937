
import React from "react";
import { useState, useMemo } from "react";
import ListTable from "../../utils/Table";

export default function RecentlyAddedSeries({ recently_added_series }) {
  // console.log(recently_added_series, "110110")
  const [tableData, setTableData] = useState({
    tableTitle: "Recently Added Series",
    disableDelete: true,
    rowsPerPage: 5,
    // fixedRow:5,
    tableHead: [
      {
        id: 'series_name',
        label: 'Name',
        link: "/Series/Series/SeriesDetails",
        color: "var(--gradientColor2)",
        // subText: "movie_subcategory",
        width: "auto",
      },
      {
        id: "series_type",
        label: "Series Type"
      },
      {
        id: 'series_poster',
        label: 'Image',
        isImage: true
      },     
      // {
      //   id: 'series_ownership',
      //   label: 'Ownership',
      // },
   
      {
        id: 'language',
        label: 'Language',
      },
      ,
      // {
      //   id: 'movieViews',
      //   label: 'views',
      // },

      // {
      //   id: 'total_downloads',
      //   label: 'Downloads',
      // }
    ],
    tableBody: recently_added_series || []
  })
  // console.log(recently_added_series,"loop12345")

  const [form, setForm] = useState({});
  const [isEdit, setIsEdit] = useState(false);





  useMemo(() => {

    if (recently_added_series != undefined) {
      const temp = tableData
      temp.tableBody = recently_added_series?.map((value, index) => ({ ...value,language:value?.language.language_name}))

      setTableData({ ...temp })
    }
  }, [recently_added_series])



  return (
    <>


      <ListTable
        tableData={tableData}
        key={"ListTable"}
        setForm={setForm}
        setTableData={setTableData}
        setIsEdit={setIsEdit}
      />

    </>
  );
}
