import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  subcategory_create,
  subcategory_delete,
  subcategory_update,
  all_subcategory_list,
} from "../../../actions/Masters/subcategory";
import { all_category_list } from "../../../actions/Masters/category";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
export default function SubCategory() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[3];
  useEffect(() => {
    setView(path != "SubCategory" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditSubCategory");
  }, [location]);
  useEffect(() => {
    dispatch(all_subcategory_list());
    dispatch(all_category_list());
  }, []);
  const [tableData, setTableData] = useState({
    tableTitle: "Sub Categories",
    deleteRecord: subcategory_delete,
    updateRecord: subcategory_update,
    onDeleteText: "Are you sure want to delete the Sub Category?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "category",
        label: "Category Name",
      },
      {
        id: "subcategory_name",
        label: "Sub Category",
      },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "1",
        title: "Select Category",
        name: "category",
        options: ["Portrait", "Landscape"],
      },
    ],
  });

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Select Category",
      name: "category",
      options: ["Portrait", "Landscape"],
      required: true,
    },

    {
      id: "2",
      type: "inputBox",
      title: "Sub Category",
      name: "subcategory_name",
      maxLength: 30,
      isCapitalise: true,
      required: true,
    },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);
  const formTitle = isEdit ? "Edit Sub Category" : "Create Sub Category";

  const subcategories = useSelector((state) => state.masters.subcategories);
  const categories = useSelector((state) => state.masters.categories);

  useMemo(() => {
    if (categories?.statuscode == 200) {
      const temp = formStructure;
      temp[0]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setFormStructure([...temp]);
      const temp1 = tableData.filterColumn;
      temp1[0]["options"] = categories?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tableData, filterColumn: temp1 });
    }
  }, [categories]);

  useMemo(() => {
    if (subcategories?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = subcategories?.data.map((ele) => ({
        ...ele,
        category: ele.category?.category_name,
      }));
      setTableData({ ...temp });
    }
  }, [subcategories]);
  const message = useSelector((state) => state.layout.message);
  // console.log(message,"789788945")
  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_form = form;
    temp_form["tempcategory"] = form?.["category"];
    temp_form["tempsubcategory_name"] = form?.["subcategory_name"];
    temp_form["subcategory_name"] = form?.["subcategory_name"].replace(
      /(^[\s]+|[\s]+$)/g,
      ""
    );
    temp_form["category"] = categories?.data
      .map(
        (option) =>
          form?.["category"]?.includes(option.category_name) && option.id
      )
      .filter((e) => e)[0];
    setForm({
      ...temp_form,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    const temp_form1 = form;
    temp_form1["category"] = form?.["tempcategory"];
    temp_form1["subcategory_name"] = form?.["tempsubcategory_name"];
    setForm({
      ...temp_form1,
    });

    if (isEdit) {
      dispatch(subcategory_update(data));
    } else {
      dispatch(subcategory_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_subcategory_list());
        navigate("/Masters/SubCategory/SubCategory", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);
  return (
    <>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/SubCategory/CreateSubCategory"}
        view_all={"/Masters/SubCategory/SubCategory"}
        form={form}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/SubCategory/EditSubCategory"}
        />
      )}
    </>
  );
}
