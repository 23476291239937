import { MESSAGE, AUDIOFILE, SUBTITLE ,COUNTRYFILE } from "./../constants/actionTypes";
import * as api from "./../api/index";

export const subtitle_delete = (formData) => async (dispatch) => {
  try {
    const { data } = await api.subtitle_delete(formData);
    dispatch({ type: SUBTITLE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const audio_file_delete = (formData) => async (dispatch) => {
  try {
    const { data } = await api.audio_file_delete(formData);
    dispatch({ type: AUDIOFILE, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const country_price_delete_from_videos =
  (formData) => async (dispatch) => {
    try {
      const { data } = await api.country_price_delete_from_videos(formData);
      dispatch({ type: COUNTRYFILE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
