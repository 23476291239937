

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import DistributorsFormData from "../components/ProducerForm/DistributorsFormData"
import DistributorsFormDetails from "../modules/DistributorsFormDetailsModule"



function DistributorsFormDataModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/DistributorsFormData' element={<Layout children={DistributorsFormData()}/>}/>
< Route path='/DistributorsFormDetails/*' element={<DistributorsFormDetails key="DistributorsFormDetails"/>}/>

      </Routes>
    </div>
  );
}

export default DistributorsFormDataModule;