

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Customer from '../components/Customer/Customer';
import Layout from '../components/Layout/Layout';
import CustomerDetail from './CustomerDetailModule';


function CustomerModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Customer' element={<Layout children={Customer()}/>}/>
< Route path='/CustomerDetail/*' element={<CustomerDetail key="CustomerDetail"/>}/>

      </Routes>
    </div>
  );
}

export default CustomerModule;